export const FETCH_QUESTIONS = 'FETCH_QUESTIONS'

export const NEW_FEEDBACK = 'NEW_FEEDBACK'

export const NEW_TEST_RESULT = 'NEW_TEST_RESULT'
export const UPDATE_TEST_RESULT = 'UPDATE_TEST_RESULT'
export const FETCH_AVERAGED_TEST_RESULTS = 'FETCH_AVERAGED_TEST_RESULTS'
export const CLEAR_AVERAGED_TEST_RESULTS = 'CLEAR_AVERAGED_TEST_RESULTS'

export const FETCH_IDEOLOGIES = 'FETCH_IDEOLOGIES'
export const FETCH_IDEOLOGY_MATCH = 'FETCH_IDEOLOGY_MATCH'

export const FETCH_COUNTIES = 'FETCH_COUNTIES'

export const CLEAR_DATA = 'CLEAR_DATA'